<template lang="html">
  <div class="">hi :)</div>
</template>
<script>
import store from "@/store/index";
export default {
  name: "Root",
  beforeRouteEnter: function (to, from, next) {
    next({ name: "Login" });
  },
};
</script>

<style lang="css" scoped></style>
